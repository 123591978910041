<template>
  <b-col
    md="6"
    lg="4"
  >
    <b-card>
      <validation-observer ref="ebildirge">
        <app-mask-input
          v-model="data.tcNo"
          name="TC Kimlik No"
          input-mask="###########"
          label="TC Kimlik No:"
          placeholder="TC Kimlik No..."
          rules="required"
          @keyup.enter.native="setUser"
        />
        <app-input
          v-model="data.password"
          type="password"
          name="Şifre"
          label="Şifre:"
          placeholder="············"
          rules="required"
          @keyup.enter.native="setUser"
        />
        <app-button
          text="Kaydet"
          block
          @click="setUser"
        />
      </validation-observer>
    </b-card>
  </b-col>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  components: { ValidationObserver },
  data() {
    return {
      data: {},
    }
  },
  methods: {
    setUser() {
      console.log(this.data)
    },
  },
}
</script>

<style></style>
