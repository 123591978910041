<template>
  <app-overlay loading-variable="modalOverlay">
    <app-input v-model="code" name="Sms Kodu" type="number" label="Sms Kodu:" placeholder="Kod Giriniz..." rules="required|length:6" @keyup.enter.native="checkCode" />
    <!-- timer -->
    <div class="d-flex justify-content-center">
      {{ timer }}
    </div>
    <hr />
    <app-button @click="checkCode" text="Onayla" block />
  </app-overlay>
</template>

<script>
export default {
  data() {
    return {
      code: null,
      timer: "02:00",
      countdown: null,
      distance: 120,
    };
  },
  methods: {
    checkCode() {
      this.$axios
        .post("/sms-confirm/check-sms", { code: this.code }, { loading: "modal" })
        .then((result) => {
          this.callback(result?.data?.token);
          this.$store.commit("cleanAppModal");
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", {
            variant: "danger",
            title: "Hata Oluştu",
            message: err?.data?.message ?? null,
          });
        });
    },
    startCountDown() {
      this.countdown = setInterval(() => {
        if (!this.distance || this.distance < 0) {
          clearInterval(this.countdown);
          this.timer = "00:00";
          this.$store.commit("cleanAppModal");
        } else {
          let minutes = Math.floor(this.distance / 60);
          let seconds = this.distance % 60;
          const timer = (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
          this.timer = timer;
          this.distance -= 1;
        }
      }, 1000);
    },
  },
  computed: {
    callback() {
      return this.$store.getters?.appModalItem?.callback;
    },
  },
  mounted() {
    this.startCountDown();
  },
  destroyed() {
    clearInterval(this.countdown);
  },
};
</script>

<style></style>
