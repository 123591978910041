<template>
  <b-card title="Şifre Güncelleme">
    <validation-observer ref="passwordchange">
      <app-input
        id="oldPassword"
        v-model="data.oldPassword"
        type="password"
        name="Eski Şifre"
        label="Eski Şifre:"
        placeholder="············"
        rules="required"
        @keyup.enter.native="showSmsModal"
      />
      <app-input
        id="password"
        v-model="data.newPassword"
        type="password"
        name="Yeni Şifre"
        label="Yeni Şifre:"
        placeholder="············"
        rules="required"
        @keyup.enter.native="showSmsModal"
      />
      <app-input
        v-model="passwordCheck"
        type="passwordCheck"
        name="Şifre Doğrulama"
        label="Şifre Doğrulama:"
        placeholder="············"
        rules="required|confirmed:password"
        @keyup.enter.native="showSmsModal"
      />
      <hr />
      <app-button text="Şifreyi Güncelle" block @click="showSmsModal" />
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import SmsCheckModal from "./SmsCheckModal.vue";

export default {
  components: { ValidationObserver },
  data() {
    return {
      data: {},
      passwordCheck: null,
    };
  },
  methods: {
    showSmsModal() {
      this.$validate(this.$refs.passwordchange, () => {
        this.$axios
          .post("/change-password/check-old-password", { oldPassword: this.data.oldPassword }, { loading: "table" })
          .then(() => {
            this.$axios
              .post("/sms-confirm/send-sms", {}, { loading: "table" })
              .then(() => {
                this.$showAppModal({
                  title: "SMS Onayı",
                  component: SmsCheckModal,
                  size: "sm",
                  item: {
                    callback: this.changePassword,
                  },
                });
              })
              .catch((err) => {
                this.$emitter.$emit("Notification", {
                  variant: "danger",
                  title: "Hata Oluştu",
                  message: err?.data?.message ?? null,
                });
              });
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", {
              variant: "danger",
              title: "Hata Oluştu",
              message: err?.data?.message ?? null,
            });
          });
      });
    },
    changePassword(token) {
      this.$validate(this.$refs.passwordchange, () => {
        this.$axios
          .patch("/change-password", { ...this.data }, { headers: { Authorization: token } })
          .then(() => {
            this.data = {};
            this.passwordCheck = null;
            this.$refs.passwordchange.reset();
            this.$emitter.$emit("Notification", {
              variant: "success",
              title: "İşlem Başarılı",
              message: "Şifreniz başarıyla güncellendi.",
            });
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", {
              variant: "danger",
              title: "Hata Oluştu",
              message: "Şifreniz değiştirilemedi",
            });
          });
      });
    },
  },
};
</script>

<style></style>
