<template>
  <b-custom-tabs :tabs="tabs" />
</template>

<script>
import customerSupport from "./customerSupport.vue";
import EBildirge from "./EBildirge.vue";
import profileVue from "./profile.vue";

export default {
  props: {
    tab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tabIndex: 0,
      tabs: [
        { title: "Profil Bilgileri", component: profileVue },
        { title: "Girişler ve Güvenlik" },
        /*  { title: 'Müşteri Temsilciniz', component: customerSupport },
        { title: 'Şikayet & İstek' },
        { title: 'E-Bildirge Kullanıcısı', component: EBildirge }, */
      ],
    };
  },
  created() {
    this.tabIndex = this.tab;
  },
};
</script>

<style></style>
