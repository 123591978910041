<template>
  <b-col
    lg="3"
    md="6"
  >
    <b-card class="border">
      <div class="d-flex justify-content-center align-items-center flex-column gap-10">
        <b-avatar
          variant="light-secondary"
          size="100"
        />
        <h4>Temsilci Adı</h4>
        <app-icon-button
          size="20"
          variant="gradient-success"
          icon="PhoneCallIcon"
        />
      </div>
    </b-card>
  </b-col>
</template>

<script>
export default {}
</script>

<style></style>
