<template>
  <app-overlay>
    <b-row>
      <b-col lg="8">
        <b-card title="Genel Bilgiler">
          <b-row v-if="user">
            <b-col md="6">
              <app-input v-model="user.fullname" name="Ad Soyad" label="Ad Soyad:" placeholder="Ad Soyad..." rules="required" />
            </b-col>
            <b-col md="6">
              <app-input v-model="user.email" name="E-Posta" label="E-Posta:" placeholder="E-Posta..." rules="required|email" />
            </b-col>
            <b-col md="6">
              <app-input v-model="user.phone" type="phone" name="Telefon" placeholder="Telefon..." label="Telefon:" rules="required|length:13" />
            </b-col>
            <b-col md="6">
              <app-input v-model="user.customer.duty" name="Unvan" label="Unvan:" placeholder="Unvan..." />
            </b-col>
            <b-col offset-md="3" md="5">
              <app-button text="Güncelle" size="md" block @click="showSmsModal" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="4">
        <password-change />
      </b-col>
    </b-row>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import passwordChange from "@/views/Physicians/userSettings/profile/passwordChange.vue";
import SmsCheckModal from "@/views/Physicians/userSettings/profile/SmsCheckModal.vue";

export default {
  components: { ValidationObserver, passwordChange },
  data() {
    return {
      user: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.user = { ...this.$store.getters?.user };
    });
  },
  methods: {
    showSmsModal() {
      this.$axios
        .post("/sms-confirm/send-sms", {}, { loading: "table" })
        .then(() => {
          this.$showAppModal({
            title: "SMS Onayı",
            component: SmsCheckModal,
            size: "sm",
            item: {
              callback: this.updateUser,
            },
          });
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", {
            variant: "danger",
            title: "Hata Oluştu",
            message: err?.data?.message ?? null,
          });
        });
    },
    updateUser(token) {
      this.$axios
        .patch(
          "/customer/own-update",
          {
            auth: {
              fullname: this.user?.fullname,
              email: this?.user?.email,
              phone: this?.user?.phone?.replaceAll(" ", ""),
            },
            data: {
              duty: this?.user?.customer?.duty,
            },
          },
          {
            headers: { Authorization: token },
            loading: "table",
          }
        )
        .then(() => {
          this.$store.commit("setUser", { ...this.user });
          this.$emitter.$emit("Notification", {
            variant: "success",
            title: "İşlem Başarılı",
          });
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", {
            variant: "danger",
            title: "Hata Oluştu",
            message: err?.data?.message ?? null,
          });
        });
    },
  },
};
</script>

<style></style>
